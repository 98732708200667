import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { UserRouter } from './components/users/UserRouter';
import { DoughnutRouter } from './components/doughnuts/DoughnutRouter'
import DoughnutMapPage from './components/maps/DoughnutMapPage'
//import { VolunteerSectionRouter } from './components/volunteersections/VolunteerSectionRouter';
//import PathMap from './components/pathmap/PathMap'
import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/doughnutmap' component={DoughnutMapPage} />
                <AuthorizeRoute path='/users' component={UserRouter} />
                <AuthorizeRoute path='/doughnuts' component={DoughnutRouter} />

                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
