import React, { Component } from 'react';
import { FormGroup, Form, Label, Input, Button, Row, Col, UncontrolledAccordion, AccordionItem, AccordionHeader } from 'reactstrap';
import { doughnutTemplate, doughnutQuestions } from './DoughnutData';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';

class DoughnutDataEditorCategory extends Component {
    constructor(props) {
        super(props);

        this.state = { category: props.category, collapsed: false };

        //const { match } = this.props;
        //this.id = match.params.id;
    }

    componentDidMount() {
        //this.retrieveFormData();
    }
    handleSegmentChange = (newsegment) => {
        let newcategory = this.state.category;
        let newsegments = [];
        for (var segment of this.state.category.segments) {
            if (newsegment.id === segment.id) {
                newsegments = [...newsegments, newsegment];
            } else {
                newsegments = [...newsegments, segment];
            }
        }
        newcategory.segments = newsegments;
        if (this.props.onUpdate) {
            this.props.onUpdate(newcategory);
        }
        this.setState({ category: newcategory });
    }
    handleCollapse = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }
    render() {
        
        return this.state.collapsed ?
            (
                <div>
                    <Row className="doughnutEditorCategoryRow" xs="12" onClick={() => { this.handleCollapse() }}>
                        <Col sm="4" className="doughnutEditorCategoryCol"><FaChevronCircleDown /> <b>{this.props.category.name}</b></Col>
                    </Row>
                </div>
            ):
            (
            <div>
                <Row className="doughnutEditorCategoryRow" xs="12" onClick={() => { this.handleCollapse() }}>
                    <Col sm="4" className="doughnutEditorCategoryCol"><FaChevronCircleUp /> <b>{this.props.category.name}</b></Col>
                    {
                        doughnutQuestions.map((question) => (
                            <Col sm="1" className="doughnutEditorCategoryCol">{question.name}</Col>
                        ))
                    }
                    <Col sm="1" className="doughnutEditorCategoryCol">Total/100</Col>
                </Row>
                {
                    this.props.category.segments.map((segment) => (
                        <DoughnutDataEditorSegment segment={segment} onChange={(s) => { this.handleSegmentChange(s) }} />
                    ))
                }
            </div>
        )
    }
}
class DoughnutDataEditorSegment extends Component {
    constructor(props) {
        super(props);

        this.state = { segment: props.segment, loading: true };

        //const { match } = this.props;
        //this.id = match.params.id;
    }

    componentDidMount() {
        //this.retrieveFormData();
    }

    handleChange = (e, q) => {
        let newvalue = parseInt(e.target.value, 10);
        let newsegment = this.state.segment;
        if (!this.state.segment.values) {
            let v = [];
            for (var item of doughnutQuestions) {
                v = [...v, { "id": item.id, "name": item.name, "value": 0 }];
            }
            newsegment.values = v;
        }
        let newvalues = [];
        let newindicator = 0;
        for (var item of newsegment.values) {
            if (item.id == q.id) {
                let newval = newvalue >= 0 ? (newvalue <= 20 ? newvalue : 20) : 0;
                newvalues = [...newvalues, { "id": item.id, "name": item.name, "value": newval }];
                newindicator += newval;
            }
            else {
                newvalues = [...newvalues, item]
                newindicator += item.value;
            }
        }
        newsegment.values = newvalues;
        newsegment.indicator = newindicator;
        if (this.props.onChange) {
            this.props.onChange(newsegment);
        }
        this.setState({ segment: newsegment });
    }

    render() {
        return (
            <Row className="doughnutEditorSegmentRow" xs="12">
                <Col xs="4" className="doughnutEditorSegmentCol">{this.props.segment.name}</Col>
                {
                    doughnutQuestions.map((question) => (
                        <Col xs="1" className="doughnutEditorSegmentCol">
                            <Input type="number" max="20" min="0"
                                onChange={(e) => { this.handleChange(e, question) }}
                                value={this.state.segment.values ? this.state.segment.values.find(o => o.id === question.id).value : ''} />
                        </Col>
                    ))
                }
                <Col xs="1" className="doughnutEditorSegmentCol">{this.props.segment.indicator}</Col>
            </Row>
        )
    }
}

class DoughnutDataEditor extends Component {
    constructor(props) {
        super(props);

        this.state = { data: props.data, loading: true };

        //const { match } = this.props;
        //this.id = match.params.id;
    }

    componentDidMount() {
        //this.retrieveFormData();
    }
    handleCategoryChange = (newcategory) => {
        let newdata = this.state.data;
        let newcategories = [];
        for (var category of this.state.data.categories) {
            if (category.id === newcategory.id) {
                newcategories = [...newcategories, newcategory];
            } else {
                newcategories = [...newcategories, category];
            }
        }
        newdata.categories = newcategories;
        this.setState({ data: newdata });
        if (this.props.onUpdate) {
            this.props.onUpdate(newdata);
        }
    }
    render() {
        return (
            <div>
                {

                    this.state.data.categories.map((category) => (
                        <DoughnutDataEditorCategory category={category} onUpdate={(c) => { this.handleCategoryChange(c) }} />
                    ))}
            </div>
        )
    }
}

export default DoughnutDataEditor;