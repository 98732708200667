export const doughnutTemplate = {
    "categories": [{
        "name": "National Park Core",
        "id": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
        "segments": [{
            "name": "Access",
            "id": "f57beab3-2855-4e60-927c-924cffe96482",
            "target": "An accessible well connected rights of way network",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Cultural Heritage",
            "id": "0a1d2504-9289-4d60-84f8-55c21444b6e5",
            "target": "No net loss in traditional community events and activities, eg Agricultural Shows and Eisteddfodau",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Darkness and Tranquility",
            "id": "18e4c653-0215-42a7-a9af-4035674eb494",
            "target": "The factors which contribute to our tranquility are quanti\uF001ed and mapped and their integrity retained\n\nNo change above \u003E0.5% radiance for NP area",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Historic Environment",
            "id": "be0d55b5-ea92-4e53-bfe8-02b2540ac464",
            "target": "Favourable condition of all historic features within our townscapes and landscapes whether scheduled or not.",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Landscape",
            "id": "7de1fd79-ae38-4218-9997-3e160f5ec26a",
            "target": "1.\tNo detrimental change\n2.\tImprove the areas identified as poor\n3.\tSeek ways to improve the quality and condition of the whole Park",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Visitor Experience",
            "id": "5dd62e57-0bf2-4e1e-8622-cebef1b99d17",
            "target": "All visitors have a positive experience when visiting the Park",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Visitor knowledge",
            "id": "a22b787d-1865-49e2-831f-a11c5e74f748",
            "target": "Improvement of knowledge and understanding of the park",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Visitor Transport",
            "id": "f5ce917a-7b66-4633-9e17-34b6f96c8913",
            "target": "At least 75% of visits to the Park are supported by a sustainable form of transport, including electric vehicles or public transport.",
            "cid": "6f02c265-192a-43c6-8a22-2276fb0b0f34",
            "indicator": 0,
            "indicatorCore": 0
        }
        ]
    }, {
        "name": "Ecological Ceiling",
        "id": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
        "segments": [{
            "name": "Air Pollution",
            "id": "d81c8f85-050d-442a-a24a-64346be4f741",
            "target": "WHO recommended upper limit of 20 microgram per cubic meter annual mean for PM10 and PM2.5 NP area",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Biodiversity Loss",
            "id": "4f0e1375-6267-40ce-a800-51651e9a49d9",
            "target": "All SAC features in favourable condition and SSSIs in appropriate management",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Chemical Pollution",
            "id": "0c5a112d-0e9f-4062-85df-f223da1b1712",
            "target": "All rivers within the Park recorded as good ecological status against Water Framework Directive",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Climate Change",
            "id": "22bec036-79c5-4ab7-a7a7-903fa0f04e82",
            "target": "Carbon consumption at a level commensurate with fair share of paris target\n\nNon CO2 GHG to be reduced by 30% from 2021 levels by 2030",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Invasive Non-natives",
            "id": "b1bebf11-5708-4ff1-b3d4-da8027494a09",
            "target": "0 instances of reinfestation in areas  where INNS control has been implemented.",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Land Conversion",
            "id": "b91b3356-3da1-4673-aa3a-c3f102fab1f1",
            "target": "Area of tree rich landscape including linear features such as hedgerows, riverbanks and roadsides and urban trees as proportion of National Park at 40% of land area",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Nitrogen and Phosphorus Loading",
            "id": "381a0c8e-934a-40b7-88da-6513ce5b098b",
            "target": "N and P levels within critical loads for freshwater and terrestrial habitat",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Soil and Freshwater Quality",
            "id": "a3ee9b58-7fd2-400d-a3e3-379595e22d02",
            "target": "less than 5% deviance from absolute soil health",
            "currentState": "50% designated waterbodies received good status, 50% received moderate against Water Framework Directive.\nSoil quality data lacking identified as future research need.",
            "cid": "f502b4d8-ac8e-4a25-b682-409cafdc17d4",
            "indicator": 0,
            "indicatorCore": 0
        }
        ]
    }, {
        "name": "Social Foundation",
        "id": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
        "segments": [{
            "name": "Education",
            "id": "20d50702-11ca-41eb-8c5c-76f5e88a8e61",
            "target": "All residents achieve  pass grades in English and maths",
            "currentState": "WIMD data shows the area as \u0027least deprived\u0027 for education indicators- however post 16 education options are limited.",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Energy",
            "id": "5a30914c-7187-4209-8890-b090a5ed41fe",
            "target": "No resident in fuel poverty",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Food",
            "id": "ca2cf536-5cd7-4fbf-a100-cc3759882588",
            "target": "No resident in food poverty",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Gender Equity",
            "id": "6217e57e-d5ef-4bbe-8c6d-b712719d96cb",
            "target": "Eradication of gender pay and pension gap within the region",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Health",
            "id": "48fdd905-37a9-4f4a-afd2-656e3f35da36",
            "target": "Life expectancy and morbidity rates at or equal to Northen European averages",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Housing",
            "id": "54c9eb21-32ab-421e-a543-18330d6e316c",
            "target": "Housing costs no more than three times median salary",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Income",
            "id": "80a05bf1-5df6-4ee4-8961-df4e503b851f",
            "target": "No resident living below the poverty line",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Justice",
            "id": "1527e0e7-ece6-434b-806d-44b8cc89cf4d",
            "target": "Crime and conviction rates at national target levels",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Language",
            "id": "9abc66be-7b62-47f3-9b14-46aee42783e8",
            "target": "All residents with knowledge of Welsh",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Political Voice",
            "id": "fdb2d50e-52fe-472f-9dd1-d1c430235a5c",
            "target": "Decision making in local government subject to community input",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Social Equity",
            "id": "7d51521b-24ce-419e-ac4d-38c43c2a570d",
            "target": "All residents ensured equal access to essential services and facilities",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Social Networks",
            "id": "e9b53a22-5149-4964-bd31-36495d0d1acf",
            "target": "levels of reported lonliness below N. European averages",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Transport",
            "id": "9bf3657f-92f7-45c8-b515-ddcd39ad3b47",
            "target": "All residents are able to utilise public transport with minimal inconvenience to undertake daily activities",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }, {
            "name": "Water",
            "id": "4e35650a-6493-4001-8267-0b2820cc0a51",
            "target": "No resident in water poverty",
            "cid": "facf97d7-d3f2-4477-9577-4766d72a8f2d",
            "indicator": 0,
            "indicatorCore": 0
        }
        ]
    }
    ]
};
export const doughnutQuestions = [
    {
        "id":"1",
        "name": "Cost",
        "desc": "To what extent does the project address the cost issues of affecting change, does it invest in action, does it make action less costly in the future, does it add to the future cost burden of affecting change"
    },
    {
        "id": "2",
        "name": "Resource",
        "desc": "To what extent does the project add resources to affecting change, does it make affecting change less resource intensive - does it add knowledge, skill or advance technological solutions to problems"
    },
    {
        "id": "3",
        "name": "Behaviour change",
        "desc": "Will the project help create behaviour change in positive ways towards meeting the policy goal what is the depth of that behaviour change - is it a community or all communities"
    },
    {
        "id": "4",
        "name": "National policy",
        "desc": "Will the project influence the design of national policy for the better - will it help implement national policy aimed at affecting transformative change towards the policy goal"
    },
    {
        "id": "5",
        "name": "Market forces",
        "desc": "to what extent does the project/programme create value for the market to shift behaviour and adapt to a new system better aligned with policy"
    }
]