import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FaChartPie,FaCalendar, FaCalendarAlt, FaMapMarkerAlt, FaPencilAlt } from 'react-icons/fa';
import {
    Container, Row, Col,
    CardDeck, Card, CardImg, CardText, CardBody, CardFooter, CardHeader,
    CardTitle, CardSubtitle, Button, CardLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService'
import BarChart from './charts/BarChart';
import Doughnut from './charts/Doughnut';
import DoughnutDataEditor from './doughnuts/DoughnutDataEditor';
import { doughnutTemplate } from './doughnuts/DoughnutData';


export class HomePlain extends Component {
    static displayName = HomePlain.name;
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            role: null,
            user: null,
            firstname: null
        };
    }
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }
    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }
    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);
        this.setState({
            isAuthenticated,
            role: user && user.role,
            user: user,
            firstname: user && user.firstname
        });
    }
    handleDataUpdate = (d) => {
        this.setState({ doughdata: d });
    }
    render() {
        return (
            <div >

                <CardDeck>
                    {this.state.isAuthenticated ? (
                        <Card>
                            <CardHeader><CardTitle tag="h4"><FaChartPie /> <Link to={ '/doughnuts/' } className="CardHeadLink"> Doughnuts</Link></CardTitle></CardHeader>
                            <CardBody>
                                <CardText>
                                    <h6>Here you can:</h6>
                                    <ul>
                                        <li><Link className='CardBodyLink' to='/doughnuts/'>List Doughnuts</Link></li>
                                        <li><Link className='CardBodyLink' to='/doughnutmap'>Doughnut Map</Link></li>
                                        <li><Link className='CardBodyLink' to='/doughnuts/edit'>Add a Doughnut</Link></li>
                                    </ul>
                                </CardText>
                            </CardBody>
                        </Card>
                    ) : ""}
                </CardDeck>
            </div>
        );
    }
}
export const Home = withTranslation('en')(HomePlain);