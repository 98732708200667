import { useD3 } from './useD3';
import React, { useRef, useState, useCallback } from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import * as d3 from 'd3';

function downloadBlob(blob, filename) {
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

function Doughnut(props) { // { data, composite }
    const doughnutTypes = [
        { id: "npc", name: "National Park Core" },
        { id: "ec", name: "Ecological Ceiling" },
        { id: "sf", name: "Social Foundation" },
        { id: "c", name: "Full Doughnut" }
    ]
    const [zoom, setZoom] = useState(props.zoom ? props.zoom : false);
    const [type, setType] = useState(props.type ? props.type : "c");
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const svgRef = useRef();
    const downloadSVG = useCallback(() => {
        const svg = svgRef.current.innerHTML;
        const blob = new Blob([svg], { type: "image/svg+xml" });
        downloadBlob(blob, `doughnut.svg`);
    }, []);
    
    // "npc","ec","sf","c"
    
    
    const ref = useD3(
        (svg) => {
            svg.selectAll("*").remove();
            let data = props.data;
            let composite = type === "c";
            const height = 764;
            const width = 764;
            //const margin = { top: 20, right: 30, bottom: 30, left: 40 };
            const margin = { left: 20, top: 20, right: 20, bottom: 20 };
            
            svg.attr("width", (width + margin.left + margin.right))
                .attr("height", (height + margin.top + margin.bottom))
                .style("width", (width + margin.left + margin.right) + "px")
                .style("height", (height + margin.top + margin.bottom) + "px")
                .append("g").attr("class", "wrapper")
                .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (height / 2 + margin.top) + ")");
            //
            const drawParkCore = (composite, svg) => {
                let chartdata = data.categories.find(x => x.id === '6f02c265-192a-43c6-8a22-2276fb0b0f34');
                let ringwidth = 30;
                //let width = chartwidth;
                let arc = d3.arc()
                    .innerRadius(width / 3 - (ringwidth + 20 + ringwidth + 1))
                    .outerRadius(width / 3 - (ringwidth + 20 + 1));
                let outerchartarc = d3.arc()
                    .innerRadius(width / 3 - (ringwidth + 20 + 1))
                    .outerRadius(width / 3 - (ringwidth + 20 + 1));
                let innerchartarc = d3.arc()
                    .innerRadius(width / 3 - (ringwidth + 20 + ringwidth + 1))
                    .outerRadius(width / 3 - (ringwidth + 20 + ringwidth + 1));
                //Turn the pie chart 90 degrees counter clockwise, so it starts at the left
                let pie = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.001)
                    .sort(null);
                let piepad = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.005)
                    .sort(null);
                //Create the donut slices and also the invisible arcs for the text
                svg.selectAll(".npcDonutArcs")
                    .data(pie(chartdata.segments))
                    .enter().append("path")
                    .attr("class", "npcDonutArcs evDataBound")
                    .attr("d", function (d, i) {
                        return arc/*.innerRadius((width / 2) )*/(d, i)
                    }
                /*arc*/)
                    .style("fill","#804C8C")
                    /*.style("fill", function (d, i) {
                        return ringbgcolor; //Other
                    })*/
                    .each(function (d, i) {
                        //Search pattern for everything between the start and the first capital L
                        var firstArcSection = /(^.+?)L/;

                        //Grab everything up to the first Line statement
                        var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                        //Replace all the comma's so that IE can handle it
                        newArc = newArc.replace(/,/g, " ");

                        //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                        //flip the end and start position
                        if (d.endAngle > 90 * Math.PI / 180) {
                            var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                            //Flip the direction of the arc by switching the start en end point (and sweep flag)
                            //of those elements that are below the horizontal line
                            var newStart = endLoc.exec(newArc)[1];
                            var newEnd = startLoc.exec(newArc)[1];
                            var middleSec = middleLoc.exec(newArc)[1];

                            //Build up the new arc notation, set the sweep-flag to 0
                            newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                        }//if

                        //Create a new invisible arc that the text can flow along
                        svg.append("path")
                            .attr("class", "npchiddenDonutArcs")
                            .attr("id", "npcDonutArc" + i)
                            .attr("d", newArc)
                            .style("fill", "none");
                    });
                //Append the label names on the inside and align
                svg.selectAll(".npcText")
                    .data(pie(chartdata.segments))
                    .enter().append("text")
                    .attr("class", "npcText donutText evDataBound")
                    .style("font-size", "10pt")
                    .style("fill","#FFFFFF")
                    //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                    .attr("dy", function (d, i) {
                        return (d.endAngle > 90 * Math.PI / 180 ? -10 : +20);
                        if (composite) {
                            return (d.endAngle > 90 * Math.PI / 180 ? -17 : +15); // top / bottom
                        } else {
                            return (d.endAngle > 90 * Math.PI / 180 ? -30 : +25);
                        }
                    })
                    .append("textPath")
                    .attr("startOffset", "50%")
                    .style("text-anchor", "middle")
                    .attr("xlink:href", function (d, i) { return "#npcDonutArc" + i; })
                    .text(function (d) {
                        return d.data.name.replace(" and", " +").replace(" Loading", "");
                        if (d.data.name.split(" ").length == 1) {
                            return d.endAngle > 90 * Math.PI / 180 ? "" : d.data.name;

                        } else {
                            return d.data.name.split(" ")[0];
                        }
                    });
                if (!composite) {
                    //    // inner chart
                    //    svg.selectAll(".npcInnerChartDonutArcs")
                    //        .data(piepad(chartdata.segments/*donutData*/))
                    //        .enter().append("path")
                    //        .attr("class", "sfInnerChartDonutArcs evDataBound")
                    //        .attr("d", function (d, i) {
                    //            return innerchartarc.innerRadius((width / 3 - (ringwidth + 20 + ringwidth + 1) -
                    //                (((width / 3 - (ringwidth + 20 + ringwidth + 1)) / 100) * d.data.indicatorCore)

                    //            ))(d, i)
                    //        }
                    ///*arc*/)
                    //        /*.style("fill", function (d, i) {
                    //            return innerchartcolor; //Other
                    //        })*/
                    //        .each(function (d, i) {
                    //            //Search pattern for everything between the start and the first capital L
                    //            var firstArcSection = /(^.+?)L/;

                    //            //Grab everything up to the first Line statement
                    //            var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                    //            //Replace all the comma's so that IE can handle it
                    //            newArc = newArc.replace(/,/g, " ");

                    //            //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                    //            //flip the end and start position
                    //            if (d.endAngle > 90 * Math.PI / 180) {
                    //                var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                    //                    middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                    //                    endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                    //                //Flip the direction of the arc by switching the start en end point (and sweep flag)
                    //                //of those elements that are below the horizontal line
                    //                var newStart = endLoc.exec(newArc)[1];
                    //                var newEnd = startLoc.exec(newArc)[1];
                    //                var middleSec = middleLoc.exec(newArc)[1];

                    //                //Build up the new arc notation, set the sweep-flag to 0
                    //                newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                    //            }//if

                    //            //Create a new invisible arc that the text can flow along
                    //            //svg.append("path")
                    //            //    .attr("class", "hiddenDonutArcs")
                    //            //    .attr("id", "donutArc" + i)
                    //            //    .attr("d", newArc)
                    //            //    .style("fill", "none");
                    //        });
                    // outer chart
                    svg.selectAll(".npcOuterChartDonutArcs")
                        .data(piepad(chartdata.segments/*donutData*/))
                        .enter().append("path")
                        .attr("class", "ecOuterChartDonutArcs evDataBound")
                        .style("fill","#00cc00")
                        .attr("d", function (d, i) {
                            return outerchartarc.outerRadius(((width / 3 - (ringwidth + 20 + 1)) +
                                (((width / 3 - (ringwidth + 20 + 1)) / 110) * d.data.indicator)
                            ))(d, i)
                        }
                /*arc*/)
                        .each(function (d, i) {
                            //Search pattern for everything between the start and the first capital L
                            let firstArcSection = /(^.+?)L/;

                            //Grab everything up to the first Line statement
                            try {
                                let newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                                //Replace all the comma's so that IE can handle it
                                newArc = newArc.replace(/,/g, " ");

                                //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                                //flip the end and start position
                                if (d.endAngle > 90 * Math.PI / 180) {
                                    let startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                        middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                        endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                                    //Flip the direction of the arc by switching the start en end point (and sweep flag)
                                    //of those elements that are below the horizontal line
                                    let newStart = endLoc.exec(newArc)[1];
                                    let newEnd = startLoc.exec(newArc)[1];
                                    let middleSec = middleLoc.exec(newArc)[1];

                                    //Build up the new arc notation, set the sweep-flag to 0
                                    newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                                }
                            } catch (ex) {
                                //console.log(ex);
                            }
                        });
                }
            }
            const drawSocialFoundation = (composite, svg) => {
                let chartdata = data.categories.find(x => x.id === 'facf97d7-d3f2-4477-9577-4766d72a8f2d');
                let ringbgcolor = "#94B5CA";
                let ringfgcolor = "#000000";
                let innerchartcolor = "#00cc00";
                let sfwidth = width;
                let ringwidth = 50;
                var textClass = "sfText";
                if (composite) {
                    textClass = "sfTextSmall";
                    sfwidth = width * 0.4;
                    ringwidth = 30;
                }
                //Create an arc function
                let arc = d3.arc()
                    .innerRadius(sfwidth / 2 - ringwidth)
                    .outerRadius(sfwidth / 2);
                let innerchartarc = d3.arc()
                    .innerRadius(sfwidth / 2 - ringwidth)
                    .outerRadius(sfwidth / 2 - ringwidth);
                let outerarc = d3.arc()
                    .innerRadius(sfwidth / 2)
                    .outerRadius(sfwidth / 2 + 21);

                //Turn the pie chart 90 degrees counter clockwise, so it starts at the left
                let pie = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.001)
                    .sort(null);
                let piepad = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.01)
                    .sort(null);
                let pienopad = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.00)
                    .sort(null);

                //////////////////////////////////////////////////////////////
                //////////////////// Create Donut Chart //////////////////////
                //////////////////////////////////////////////////////////////

                //Create the donut slices and also the invisible arcs for the text
                svg.selectAll(".sfDonutArcs" + (composite ? "Cmp" : ""))
                    .data(pie(chartdata.segments/*donutData*/))
                    .enter().append("path")
                    .attr("class", "sfDonutArcs evDataBound")
                    .style("fill","#94B5CA")
                    .attr("d", function (d, i) {
                        return arc/*.innerRadius((width / 2) )*/(d, i)
                    }
                /*arc*/)
                    /*.style("fill", function (d, i) {
                        return ringbgcolor; //Other
                    })*/
                    .each(function (d, i) {
                        //Search pattern for everything between the start and the first capital L
                        var firstArcSection = /(^.+?)L/;

                        //Grab everything up to the first Line statement
                        var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                        //Replace all the comma's so that IE can handle it
                        newArc = newArc.replace(/,/g, " ");

                        //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                        //flip the end and start position
                        if (d.endAngle > 90 * Math.PI / 180) {
                            var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                            //Flip the direction of the arc by switching the start en end point (and sweep flag)
                            //of those elements that are below the horizontal line
                            var newStart = endLoc.exec(newArc)[1];
                            var newEnd = startLoc.exec(newArc)[1];
                            var middleSec = middleLoc.exec(newArc)[1];

                            //Build up the new arc notation, set the sweep-flag to 0
                            newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                        }//if

                        //Create a new invisible arc that the text can flow along
                        svg.append("path")
                            .attr("class", "sfhiddenDonutArcs" + (composite ? "Cmp" : ""))
                            .attr("id", "sfdonutArc" + (composite ? "Cmp" : "") + i)
                            .attr("d", newArc)
                            .style("fill", "none");
                    });

                // inner chart
                svg.selectAll(".sfInnerChartDonutArcs" + (composite ? "Cmp" : ""))
                    .data(piepad(chartdata.segments/*donutData*/))
                    .enter().append("path")
                    .attr("class", "sfInnerChartDonutArcs evDataBound")
                    .style("fill","#00cc00")
                    .attr("d", function (d, i) {
                        return innerchartarc.innerRadius((sfwidth / 2 - ringwidth -
                            (((sfwidth / 2 - ringwidth) / 100) * d.data.indicator)
                        ))(d, i)
                    }
                /*arc*/)
                    /*.style("fill", function (d, i) {
                        return innerchartcolor; //Other
                    })*/
                    .each(function (d, i) {
                        //Search pattern for everything between the start and the first capital L
                        var firstArcSection = /(^.+?)L/;

                        //Grab everything up to the first Line statement
                        try {
                            var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                            //Replace all the comma's so that IE can handle it
                            newArc = newArc.replace(/,/g, " ");

                            //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                            //flip the end and start position
                            if (d.endAngle > 90 * Math.PI / 180) {
                                var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                    middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                    endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                                //Flip the direction of the arc by switching the start en end point (and sweep flag)
                                //of those elements that are below the horizontal line
                                var newStart = endLoc.exec(newArc)[1];
                                var newEnd = startLoc.exec(newArc)[1];
                                var middleSec = middleLoc.exec(newArc)[1];

                                //Build up the new arc notation, set the sweep-flag to 0
                                newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                            }//if
                        } catch (ex) { }
                        //Create a new invisible arc that the text can flow along
                        //svg.append("path")
                        //    .attr("class", "hiddenDonutArcs")
                        //    .attr("id", "donutArc" + i)
                        //    .attr("d", newArc)
                        //    .style("fill", "none");
                    });


                //Append the label names on the inside and align
                svg.selectAll(".sfDonutText" + (composite ? "Cmp" : ""))
                    .data(pie(chartdata.segments/*donutData*/))
                    .enter().append("text")
                    .attr("class", "sfDonutText" + (composite ? "Cmp" : "") + " donutText " + textClass + " evDataBound")
                    .style("font-size", textClass == "sfTextSmall" ? "8pt" : "10pt")
                    //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                    .attr("dy", function (d, i) {
                        if (composite) {
                            return (d.endAngle > 90 * Math.PI / 180 ? -17 : +15); // top / bottom
                        } else {
                            return (d.endAngle > 90 * Math.PI / 180 ? -30 : +25);
                        }
                    })
                    .append("textPath")
                    .attr("startOffset", "50%")
                    .style("text-anchor", "middle")
                    .attr("xlink:href", function (d, i) { return "#sfdonutArc" + (composite ? "Cmp" : "") + i; })
                    .text(function (d) {
                        if (d.data.name.split(" ").length == 1) {
                            return d.endAngle > 90 * Math.PI / 180 ? "" : d.data.name;

                        } else {
                            return d.data.name.split(" ")[0];
                        }
                    });

                svg.selectAll(".sfdonutText2" + (composite ? "Cmp" : ""))
                    .data(pie(chartdata.segments/*donutData*/))
                    .enter().append("text")
                    .attr("class", "donutText " + textClass + " evDataBound")
                    //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                    .attr("dy", function (d, i) {
                        if (composite) {
                            return (d.endAngle > 90 * Math.PI / 180 ? -7 : +25); // bottom / top
                        } else {
                            return (d.endAngle > 90 * Math.PI / 180 ? -14 : +40);
                        }
                    })
                    .append("textPath")
                    .attr("startOffset", "50%")
                    .style("text-anchor", "middle")
                    .attr("xlink:href", function (d, i) { return "#sfdonutArc" + (composite ? "Cmp" : "") + i; })
                    .text(function (d) {
                        if (d.data.name.split(" ").length == 1) {
                            return d.endAngle > 90 * Math.PI / 180 ? d.data.name : "";

                        } else {
                            return d.data.name.split(" ")[1];
                        }
                    });
                /*
                 * 
                 * */
                if (composite) {
                    var innerringdata = [{ name: "Duty - Social Foundation", value: 10 }, { name: "", value: 10 }];
                    svg.selectAll(".sfDonutArcsOuter")
                        .data(pienopad(innerringdata /*evdata.ec.folders */))
                        .enter().append("path")
                        .attr("class", "sfDonutArcsOuter")
                        .style("fill","#30633A")
                        .attr("d", function (d, i) {
                            return outerarc/*.innerRadius((width / 2) )*/(d, i)
                        }
                    /*arc*/)
                        /*.style("fill", function (d, i) {
                            return ringbgcolor; //Other
                        })*/
                        .each(function (d, i) {
                            //Search pattern for everything between the start and the first capital L
                            var firstArcSection = /(^.+?)L/;

                            //Grab everything up to the first Line statement
                            var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                            //Replace all the comma's so that IE can handle it
                            newArc = newArc.replace(/,/g, " ");

                            //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                            //flip the end and start position
                            if (d.endAngle > 90 * Math.PI / 180) {
                                var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                    middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                    endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                                //Flip the direction of the arc by switching the start en end point (and sweep flag)
                                //of those elements that are below the horizontal line
                                try {
                                    var newStart = endLoc.exec(newArc)[1];
                                    var newEnd = startLoc.exec(newArc)[1];
                                    var middleSec = middleLoc.exec(newArc)[1];

                                    //Build up the new arc notation, set the sweep-flag to 0
                                    newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                                } catch (ex) { }
                            }//if

                            //Create a new invisible arc that the text can flow along
                            svg.append("path")
                                .attr("class", "sfouterhiddenDonutArcs")
                                .attr("id", "sfOuterDonutArc" + i)
                                .attr("d", newArc)
                                .style("fill", "none");
                        });
                    //ecTextInner
                    svg.selectAll(".sfTextOuter")
                        .data(pienopad(innerringdata))
                        .enter().append("text")
                        .attr("class", "sfTextOuter donutText evDataBound")
                        //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                        .attr("dy", function (d, i) {
                            return (d.endAngle > 90 * Math.PI / 180 ? -10 : +15);
                            if (composite) {
                                return (d.endAngle > 90 * Math.PI / 180 ? -17 : +15); // top / bottom
                            } else {
                                return (d.endAngle > 90 * Math.PI / 180 ? -30 : +25);
                            }
                        })
                        .append("textPath")
                        .attr("startOffset", "50%")
                        .style("text-anchor", "middle")
                        .style("font-size", "10pt")
                        .style("fill", "#FFFFFF")
                        .attr("xlink:href", function (d, i) { return "#sfOuterDonutArc" + i; })
                        .text(function (d) {
                            return d.data.name.replace("and", "+").replace(" Loading", "");
                            if (d.data.name.split(" ").length == 1) {
                                return d.endAngle > 90 * Math.PI / 180 ? "" : d.data.name;

                            } else {
                                return d.data.name.split(" ")[0];
                            }
                        });
                }
            }
            const drawEcologicalCeiling = (composite, svg) => {
                let chartdata = data.categories.find(x => x.id === 'f502b4d8-ac8e-4a25-b682-409cafdc17d4');
                let ringwidth = 30;
                let textClass = "ecText";
                //Create an arc function
                let arc = d3.arc()
                    .innerRadius(width / 3 - ringwidth)
                    .outerRadius(width / 3);
                let outerchartarc = d3.arc()
                    .innerRadius(width / 3)
                    .outerRadius(width / 3);
                let innerringarc = d3.arc()
                    .innerRadius(width / 3 - (ringwidth + 22))
                    .outerRadius(width / 3 - ringwidth);
                //
                //Turn the pie chart 90 degrees counter clockwise, so it starts at the left
                let pie = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.001)
                    .sort(null);
                let piepad = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.005)
                    .sort(null);
                let pienopad = d3.pie()
                    .startAngle(-90 * Math.PI / 180)
                    .endAngle(-90 * Math.PI / 180 + 2 * Math.PI)
                    .value(function (d) { return 20/*d.value*/; })
                    .padAngle(.00)
                    .sort(null);
                //Create the donut slices and also the invisible arcs for the text
                svg.selectAll(".ecDonutArcs")
                    .data(pie(chartdata.segments/*donutData*/))
                    .enter().append("path")
                    .attr("class", "ecDonutArcs evDataBound")
                    .style("fill","#99C471")
                    .attr("d", function (d, i) {
                        return arc/*.innerRadius((width / 2) )*/(d, i)
                    }
                /*arc*/)
                    /*.style("fill", function (d, i) {
                        return ringbgcolor; //Other
                    })*/
                    .each(function (d, i) {
                        //Search pattern for everything between the start and the first capital L
                        var firstArcSection = /(^.+?)L/;

                        //Grab everything up to the first Line statement
                        var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                        //Replace all the comma's so that IE can handle it
                        newArc = newArc.replace(/,/g, " ");

                        //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                        //flip the end and start position
                        if (d.endAngle > 90 * Math.PI / 180) {
                            var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                            //Flip the direction of the arc by switching the start en end point (and sweep flag)
                            //of those elements that are below the horizontal line
                            var newStart = endLoc.exec(newArc)[1];
                            var newEnd = startLoc.exec(newArc)[1];
                            var middleSec = middleLoc.exec(newArc)[1];

                            //Build up the new arc notation, set the sweep-flag to 0
                            newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                        }//if

                        //Create a new invisible arc that the text can flow along
                        svg.append("path")
                            .attr("class", "echiddenDonutArcs")
                            .attr("id", "ecDonutArc" + i)
                            .attr("d", newArc)
                            .style("fill", "none");
                    });
                // outer chart
                svg.selectAll(".ecOuterChartDonutArcs")
                    .data(piepad(chartdata.segments/*donutData*/))
                    .enter().append("path")
                    .attr("class", "ecOuterChartDonutArcs evDataBound")
                    .style("fill","#00cc00")
                    .attr("d", function (d, i) {
                        return outerchartarc.outerRadius(((width / 3) +
                            (((width / 3) / 173) * d.data.indicator)
                        ))(d, i)
                    }
                /*arc*/)
                    .each(function (d, i) {
                        //Search pattern for everything between the start and the first capital L
                        var firstArcSection = /(^.+?)L/;

                        //Grab everything up to the first Line statement
                        try {
                            var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                            //Replace all the comma's so that IE can handle it
                            newArc = newArc.replace(/,/g, " ");

                            //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                            //flip the end and start position
                            if (d.endAngle > 90 * Math.PI / 180) {
                                var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                    middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                    endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                                //Flip the direction of the arc by switching the start en end point (and sweep flag)
                                //of those elements that are below the horizontal line
                                var newStart = endLoc.exec(newArc)[1];
                                var newEnd = startLoc.exec(newArc)[1];
                                var middleSec = middleLoc.exec(newArc)[1];

                                //Build up the new arc notation, set the sweep-flag to 0
                                newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                            }
                        } catch (ex) { }
                    });
                //Append the label names on the inside and align
                svg.selectAll("." + textClass)
                    .data(pie(chartdata.segments/*donutData*/))
                    .enter().append("text")
                    .attr("class", textClass + " donutText evDataBound")
                    //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                    .attr("dy", function (d, i) {
                        return (d.endAngle > 90 * Math.PI / 180 ? -10 : +20);
                        if (composite) {
                            return (d.endAngle > 90 * Math.PI / 180 ? -17 : +15); // top / bottom
                        } else {
                            return (d.endAngle > 90 * Math.PI / 180 ? -30 : +25);
                        }
                    })
                    .append("textPath")
                    .attr("startOffset", "50%")
                    .style("text-anchor", "middle")
                    .style("font-size", "10pt")
                    .attr("xlink:href", function (d, i) { return "#ecDonutArc" + i; })
                    .text(function (d) {
                        return d.data.name.replace(" and", " +").replace(" Loading", "");
                        if (d.data.name.split(" ").length == 1) {
                            return d.endAngle > 90 * Math.PI / 180 ? "" : d.data.name;

                        } else {
                            return d.data.name.split(" ")[0];
                        }
                    });
                // inner ring
                var innerringdata = [{ name: "First Purpose - Ecological Ceiling", value: 10 }, { name: "", value: 10 }];
                svg.selectAll(".ecDonutArcsInner")
                    .data(pienopad(innerringdata /*evdata.ec.folders */))
                    .enter().append("path")
                    .attr("class", "ecDonutArcsInner")
                    .style("fill","#30633A")
                    .attr("d", function (d, i) {
                        return innerringarc/*.innerRadius((width / 2) )*/(d, i)
                    }
                /*arc*/)
                    /*.style("fill", function (d, i) {
                        return ringbgcolor; //Other
                    })*/
                    .each(function (d, i) {
                        //Search pattern for everything between the start and the first capital L
                        var firstArcSection = /(^.+?)L/;

                        //Grab everything up to the first Line statement
                        var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                        //Replace all the comma's so that IE can handle it
                        newArc = newArc.replace(/,/g, " ");

                        //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
                        //flip the end and start position
                        if (d.endAngle > 90 * Math.PI / 180) {
                            var startLoc = /M(.*?)A/,		//Everything between the first capital M and first capital A
                                middleLoc = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
                                endLoc = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
                            //Flip the direction of the arc by switching the start en end point (and sweep flag)
                            //of those elements that are below the horizontal line
                            try {
                                var newStart = endLoc.exec(newArc)[1];
                                var newEnd = startLoc.exec(newArc)[1];
                                var middleSec = middleLoc.exec(newArc)[1];

                                //Build up the new arc notation, set the sweep-flag to 0
                                newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
                            } catch (ex) { }
                        }//if

                        //Create a new invisible arc that the text can flow along
                        svg.append("path")
                            .attr("class", "ecinnerhiddenDonutArcs")
                            .attr("id", "ecInnerDonutArc" + i)
                            .attr("d", newArc)
                            .style("fill", "none");
                    });
                //ecTextInner
                svg.selectAll(".ecTextInner")
                    .data(pienopad(innerringdata))
                    .enter().append("text")
                    .attr("class", "ecTextInner donutText ")
                    .style("font-size", "10pt")
                    .style("fill", "#FFFFFF")
                    //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
                    .attr("dy", function (d, i) {
                        return (d.endAngle > 90 * Math.PI / 180 ? -10 : +15);
                        if (composite) {
                            return (d.endAngle > 90 * Math.PI / 180 ? -17 : +15); // top / bottom
                        } else {
                            return (d.endAngle > 90 * Math.PI / 180 ? -30 : +25);
                        }
                    })
                    .append("textPath")
                    .attr("startOffset", "50%")
                    .style("text-anchor", "middle")
                    .attr("xlink:href", function (d, i) { return "#ecInnerDonutArc" + i; })
                    .text(function (d) {
                        return d.data.name.replace("and", "+").replace(" Loading", "");
                        if (d.data.name.split(" ").length == 1) {
                            return d.endAngle > 90 * Math.PI / 180 ? "" : d.data.name;

                        } else {
                            return d.data.name.split(" ")[0];
                        }
                    });
            }
            // ***********************
            // ***********************
            if (type === "c") {
                drawParkCore(true, svg.select('g'));
                drawSocialFoundation(true, svg.select('g'));
                drawEcologicalCeiling(true, svg.select('g'));
            } else if (type === "npc") {
                drawParkCore(false, svg.select('g'));
            } else if (type === "ec") {
                drawEcologicalCeiling(false, svg.select('g'));
            } else if (type === "sf") {
                drawSocialFoundation(false, svg.select('g'));
            }

        },
        [props,type]
    );

    return (
        <div style={{ display: "inline-block" }} >
            <div ref={svgRef}>
                <svg
                    ref={ref}
                    style={{
                        height: 800,
                        width: 800,
                        marginRight: "0px",
                        marginLeft: "0px",
                        zoom: ( zoom ? "100%" : "65%")
                    }}
                >
                </svg>
            </div>
            <div>
                <Dropdown color="primary" isOpen={dropdownOpen} toggle={toggle} style={{ display: "inline" }}>
                    <DropdownToggle color="primary" caret>
                        {doughnutTypes.find(o => o.id === type).name}
                    </DropdownToggle>
                    <DropdownMenu color="primary" container="body" style={{ zIndex:100000 }}>
                        {
                            doughnutTypes.map((dt) => (
                                <DropdownItem style={{
                                    color: "#fff",
                                    borderColor: "#0062cc",
                                }} onClick={function () { setType(dt.id); }}>
                                    {dt.name}
                                </DropdownItem>
                                ))
                        }
                    </DropdownMenu>
                </Dropdown>
                &nbsp;
                <Button color="primary" onClick={function () { setZoom(!zoom) }}>{zoom ? "Small" : "Large"}</Button>
                &nbsp;
                <Button color="primary" onClick={downloadSVG}>Download</Button>
            </div>
        </div>
    );
}

export default Doughnut;