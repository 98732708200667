import "proj4leaflet";

import proj4 from "proj4";

import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import {
    Map,
    WMSTileLayer,
    LayersControl,
    Polyline,
    Popup,
    Marker,
    withLeaflet,
    ZoomControl
} from "react-leaflet";
import { Button } from "reactstrap";
import L from "leaflet";
import Control from "react-leaflet-control";
import 'leaflet/dist/leaflet.css';





//import PopupContent from "./PopupContent";
//import Legend from "./Legend";
//import Welcome from "./Welcome";
//import Report from "./Report";
import { withNamespaces } from "react-i18next";
//import AutoOpenPopupMarker from "./AutoOpenPopupMarker";
import GeoLocation from "./GeoLocation";
import MeasureControlDefault from "react-leaflet-measure";
//import Lockr from "lockr";
import { wgs84, osgb } from "../osgb";

const MeasureControl = withLeaflet(MeasureControlDefault);
const { BaseLayer, Overlay } = LayersControl;

var EPSG27700 =
    "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs";

var crs = new L.Proj.CRS("EPSG:27700", EPSG27700, {
    //scales: [1600,800,400,200,100,50,25,10,5,2.5,1,0.5,0.25,0.125,0.0625]
    scales: [0.03125, 0.0625, 0.125, 0.25, 0.5, 1, 2.5, 5]
    //resolutions: [250, 80, 35, 12.35, 8, 4, 2, 1]
});

const DEFAULT_VIEWPORT = {
    center: [51.87, -3.45],
    zoom: 0
};

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class PathMap extends Component {
    constructor(props) {
        super(props);
        this.handleClickMap = this.handleClickMap.bind(this);

        //this.toggleLegend = this.toggleLegend.bind(this);
        //this.toggleIntro = this.toggleIntro.bind(this);
        //this.toggleReportForm = this.toggleReportForm.bind(this);
        //this.getSelectedPRoW = this.getSelectedPRoW.bind(this);

        this.leaflet = React.createRef();
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        let setviewport = DEFAULT_VIEWPORT;
        if (this.props.selectedLocation) {

            let latlng = new L.LatLng(
                props.selectedLocation.lat,
                props.selectedLocation.lng
            );
            setviewport = { center: latlng, zoom: 1 };

        }

        this.state = {
            featureCollection: {},
            selectedLocation: props.selectedLocation,
            showLegend: false,
            showIntro: this.showIntro(),
            showReportForm: false,
            usersLocation: null,
            viewport: setviewport
        };
    }
    showIntro() {
        return false;
        //    //let hasSeenIntro = Lockr.get("hasSeenIntro");
        //    let hasSeenIntro = false;
        //    //User has already accepted terms, no need to show welcome
        //    if (hasSeenIntro) {
        //        return false;
        //    } else {
        //        Lockr.set("hasSeenIntro", true);
        //        return true;
        //    }
    }
    handleClickMap(event) {
        if (this.props.isStatic) {

        }
        else {
            let url = this.getFeatureInfoUrl(event.latlng, event.target);

            this.wgs84.setDegrees(event.latlng.lat, event.latlng.lng);

            this.setState({
                selectedLocation: event.latlng
            });

            if (this.props.onClick) {
                this.props.onClick(event, null);
            }
            //fetch(url)
            //    .then(collections => {
            //        collections
            //            .json()
            //            .then(json => {
            //                this.setState({
            //                    featureCollection: json,
            //                    selectedLocation: event.latlng
            //                });
            //                if (this.props.onClick) {
            //                    this.props.onClick(event, json);
            //                }
            //            })
            //            .catch(e => {
            //                if (this.props.onClick) {
            //                    this.props.onClick(event, {});
            //                }
            //                console.log("Failed to get response" + e);
            //            });
            //    });
        }
    }

    //showFeature() { }

    //getSelectedPRoW() {
    //    if (
    //        this.state.featureCollection.hasOwnProperty("features") &&
    //        this.state.featureCollection.features.length > 0 &&
    //        this.state.featureCollection.features[0].id.startsWith(
    //            "public_rights_of_ways"
    //        )
    //    ) {
    //        return this.state.featureCollection.features[0];
    //    }
    //}
    //translateFeatureGeomToLatLon(feature) {
    //    let pathSections = [];
    //    for (
    //        var pathSection = 0;
    //        pathSection < feature.geometry.coordinates.length;
    //        pathSection++
    //    ) {
    //        let pathPoints = [];

    //        for (
    //            var pathPointsCount = 0;
    //            pathPointsCount < feature.geometry.coordinates[pathSection].length;
    //            pathPointsCount++
    //        ) {
    //            let reprojectedCoords = proj4(
    //                EPSG27700,
    //                "WGS84",
    //                feature.geometry.coordinates[pathSection][pathPointsCount]
    //            );

    //            pathPoints.push([reprojectedCoords[1], reprojectedCoords[0]]);
    //        }

    //        pathSections.push(pathPoints);
    //    }
    //    return pathSections;
    //}

    getFeatureInfoUrl(latlng, map) {
        // Construct a GetFeatureInfo request URL given a point

        var point = map.latLngToContainerPoint(latlng, map.getZoom()),
            size = map.getSize(),
            // this crs is used to show layer added to map
            crs = map.options.crs,
            // these are the SouthWest and NorthEast points
            // projected from LatLng into used crs
            sw = crs.project(map.getBounds().getSouthWest()),
            ne = crs.project(map.getBounds().getNorthEast()),
            params = {
                request: "GetFeatureInfo",
                service: "WMS",

                // this is the code of used crs
                srs: crs.code,
                version: "1.3.0",
                feature_count: 1,

                // these are bbox defined by SouthWest and NorthEast coords
                bbox: sw.x + "," + sw.y + "," + ne.x + "," + ne.y,
                height: size.y,
                width: size.x,
                buffer: 5,
                layers: [
                    //"bbnpa_external:prow_ongoing_pathworks",
                    //"inspire:public_rights_of_ways"
                    "volunteers:volunteer_work_programme"
                ],
                query_layers: [
                    //"bbnpa_external:prow_ongoing_pathworks",
                    //"inspire:public_rights_of_ways"
                    "volunteers:volunteer_work_programme"
                ],
                info_format: "application/json"
            };

        params[params.version === "1.3.0" ? "i" : "x"] = Math.round(point.x);
        params[params.version === "1.3.0" ? "j" : "y"] = Math.round(point.y);

        return (
            "https://gis.beacons-npa.gov.uk/geoserver/ows/" +
            L.Util.getParamString(params, null, true)
        );
    }

    //toggleLegend() {
    //    this.setState({ showLegend: !this.state.showLegend });
    //}

    //toggleIntro() {
    //    this.setState({ showIntro: !this.state.showIntro });
    //}

    //toggleReportForm() {
    //    this.setState({ showReportForm: !this.state.showReportForm });
    //}

    //onViewportChanged = viewport => {
    //    if (JSON.stringify(viewport) !== JSON.stringify(this.state.viewport)) {
    //        this.setState({ viewport });
    //    }
    //};

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedLocation) {
            let latlng = new L.LatLng(
                nextProps.selectedLocation.lat,
                nextProps.selectedLocation.lng
            );
            let newviewport = { center: latlng };
            if (this.props.selectZoom) {
                newviewport.zoom = this.props.selectZoom;
            }
            this.setState({ selectedLocation: nextProps.selectedLocation, viewport: newviewport });
        }
        else {
            this.setState({ selectedLocation: null });
        }
        //    if (nextProps.searchSelection) {
        //        let latlng = new L.LatLng(
        //            nextProps.searchSelection.location[0],
        //            nextProps.searchSelection.location[1]
        //        );
        //        this.setState({ viewport: { center: latlng, zoom: 5 } });
        //    }
    }

    mouseMoved = e => {
        if (this.leaflet) {
            this.wgs84.setDegrees(e.latlng.lat, e.latlng.lng);
            let osgb = this.wgs84.getOSGB();
            let prefix = "";
            try {
                prefix = osgb.getGridRef(6) +
                    " | " +
                    e.latlng.lat.toFixed(4) +
                    " " +
                    e.latlng.lng.toFixed(4)
            } catch (e) {

            }
            this.leaflet.current.leafletElement.attributionControl.setPrefix(
                prefix
            );
        }
    };

    //removeFeature = () => {
    //    this.setState({ featureCollection: [] });
    //};

    render() {
        //let feature = this.getSelectedPRoW();
        //const { t } = i18next.t;//this.props;
        let t = (s) => s;
        let marker;
        if (this.state.selectedLocation) {
            marker = (
                <Marker
                    autoPan={true}
                    position={this.state.selectedLocation}
                >
                </Marker>
            )
        }
        //if (feature) {
        //    marker = (
        //        <AutoOpenPopupMarker
        //            autoPan={false}
        //            position={this.state.selectedLocation}
        //            onPopupclose={this.removeFeature}
        //        >
        //            <Popup autoPan={false}>
        //                <PopupContent
        //                    key={feature.id}
        //                    {...feature.properties}
        //                    selectedLocation={this.state.selectedLocation}
        //                    reportIssue={this.toggleReportForm}
        //                />
        //            </Popup>
        //        </AutoOpenPopupMarker>
        //    );
        //}

        //var renderedLine = null;
        //if (feature) {
        //    if (feature.properties.hasOwnProperty("status")) {
        //        var lineColour = "";
        //        var dashArray = "1";
        //        switch (feature.properties.status) {
        //            case 1: //Footpath
        //                lineColour = "#B141E3";
        //                break;
        //            case 2: //Bridleway
        //                lineColour = "#1FAE0E";
        //                break;
        //            case 3: //BOAT
        //                lineColour = "#7F4229";
        //                break;
        //            case 4: //RB
        //                dashArray = "4 8";
        //                lineColour = "#31EE03";
        //                break;
        //            default:
        //                lineColour = "#000000";
        //        }
        //    }
        //    renderedLine = (
        //        <Polyline
        //            color={lineColour}
        //            dashArray={dashArray}
        //            positions={this.translateFeatureGeomToLatLon(feature)}
        //        />
        //    );
        //}

        //let searchMarker = null;
        //if (this.props.searchSelection) {
        //    let latlng = new L.LatLng(
        //        this.props.searchSelection.location[0],
        //        this.props.searchSelection.location[1]
        //    );
        //    searchMarker = (
        //        <AutoOpenPopupMarker autoPan={false} position={latlng}>
        //            <Popup open={true} autoPan={false}>
        //                <h3>{this.props.searchSelection.term}</h3>
        //            </Popup>
        //        </AutoOpenPopupMarker>
        //    );
        //}

        return (

            <Map
                style={{ width: "auto", height: "100%" }}
                onMouseMove={this.mouseMoved}
                crs={crs}
                viewport={this.state.viewport}
                onViewportChanged={this.onViewportChanged}
                onClick={this.handleClickMap}
                ref={this.leaflet}
                maxZoom={6}
                zoomControl={false}
            >
                <ZoomControl
                    zoomInTitle={t("zoom-in")}
                    zoomOutTitle={t("zoom-out")}
                />
                <GeoLocation />
                {/*<Control position="bottomleft">*/}
                {/*    <Button*/}
                {/*        className={"mb-4"}*/}
                {/*        style={{ backgroundColor: "white", color: "grey" }}*/}
                {/*        outline*/}
                {/*        color="secondary"*/}
                {/*        onClick={this.toggleLegend}*/}
                {/*    >*/}
                {/*        {t("explorer-map-legend")}*/}
                {/*    </Button>*/}
                {/*</Control>*/}

                <LayersControl position="topright">
                    <BaseLayer checked={false} name={t("os")}>
                        <WMSTileLayer
                            url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                            layers={"ospmsacomposite"}
                            format={"image/png"}
                            attribution={t("os-copyright")}
                        />
                    </BaseLayer>

                    <BaseLayer checked={false} name={t("os-greyscale")}>
                        <WMSTileLayer
                            url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                            layers={"ospmsacomposite"}
                            format={"image/png"}
                            styles={"Grey"}
                            attribution={t("os-copyright")}
                        />
                    </BaseLayer>

                    <BaseLayer checked={true} name={t("os-faded")}>
                        <WMSTileLayer
                            url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/ospmsawales.wmsx"
                            layers={"ospmsacomposite"}
                            format={"image/png"}
                            styles={"Faded"}
                            attribution={t("os-copyright")}
                        />
                    </BaseLayer>

                    <BaseLayer checked={false} name={t("os-open-data")}>
                        <WMSTileLayer
                            url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/osopenwales.wmsx"
                            layers={"OSVectorMapDistrict"}
                            format={"image/png"}
                        />
                    </BaseLayer>

                    <BaseLayer checked={false} name={t("layer-aerial")}>
                        <WMSTileLayer
                            url="//www.getmapping.com/web.map.service/bbnpawms/37ohm08/wales.wmsx"
                            layers={"2017_UK_125mm"}
                            format={"image/jpeg"}
                        />
                    </BaseLayer>

                    <Overlay checked={true} name={t("overlay-park-boundary")}>
                        <WMSTileLayer
                            layers={"inspire:brecon_beacons_national_park_boundary"}
                            url="//gis.beacons-npa.gov.uk/geoserver/ows"
                            transparent={"true"}
                            format={"image/png"}
                        />
                    </Overlay>

                    <Overlay checked={true} name={t("overlay-prows")}>
                        <WMSTileLayer
                            layers={"inspire:public_rights_of_ways"}
                            styles={"prow_explorer"}
                            url="//gis.beacons-npa.gov.uk/geoserver/ows"
                            transparent={"true"}
                            format={"image/png"}
                        />
                    </Overlay>
                    <Overlay checked={true} name={t("overlay-paths")}>
                        <WMSTileLayer
                            layers={"volunteers:volunteer_work_programme"}
                            url="//gis.beacons-npa.gov.uk/geoserver/volunteers/wms"
                            transparent={"true"}
                            format={"image/png"}
                        />
                    </Overlay>
                </LayersControl>
                {/*<MeasureControl*/}
                {/*    activeColor="#dc3545"*/}
                {/*    completedColor="#007bff"*/}
                {/*    position="topleft"*/}
                {/*/>*/}
                {/*{renderedLine}*/}
                {/*{searchMarker}*/}
                {marker}
                {this.props.children}
            </Map>
        );
    }
}

export default withTranslation('en')(PathMap);