import React, { Component } from "react";
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
//import pathService from '../paths/PathService';

class LatLngSelector extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            selectedfeature: null,
            selectedgridref: null,
            selectedlocation: null
        };
    }
    onClickHandler = (event, features) => {
        //this.wgs84.setDegrees(event.latlng.lat, event.latlng.lng);
        //convert to OSGB
        //let osgbref = this.wgs84.getOSGB();
        //this.osgb.parseGridRef(osgbref.getGridRef(6));
        // let wgs84ref = this.osgb.getWGS84();
        if (this.props.onChange) {
            this.props.onChange(event.latlng);
        }
    }

    render() {
        let wgs84ref = null;
        let invalid = false;

        return (
            <div style={{ width: "100%", height: "400px" }}>
                <PathMap onClick={this.onClickHandler} selectedLocation={this.props.value && this.props.value.lng != 0 && this.props.value.lon !=0 ? this.props.value : null} isStatic={this.props.isStatic} selectZoom={1}>
                </PathMap>
            </div>
        );


    }
}
export default withTranslation('en')(LatLngSelector);