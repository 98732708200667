import React, { Component } from 'react';
import { withRouter } from 'react-router';
import doughnutService from './DoughnutService';
import { Button } from 'reactstrap';

class DoughnutDeletePlain extends Component {
	constructor(props) {
		super(props);
		this.state = { users: [], loading: true };

		const { match } = this.props;
		this.id = match.params.id;
	}

	componentDidMount() {

	}

	handleClickOk = () => {
		const { history } = this.props;

		(async () => {
			await doughnutService.delete(this.id);
			history.push('/doughnuts');
		})();
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/doughnuts');
	}

	render() {
		return (
			<div>
				<h2>Are you sure you want to delete doughnut ?</h2>

				<Button onClick={this.handleClickOk}>Yes</Button>
				<Button onClick={this.handleClickCancel}>No</Button>
			</div>
		);
	}
}
export const DoughnutDelete = withRouter(DoughnutDeletePlain);