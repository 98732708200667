import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { DoughnutList } from './DoughnutList';
//import { DoughnutAdd } from './DoughnutAdd';
import { DoughnutDelete } from './DoughnutDelete';
import { DoughnutEdit } from './DoughnutEdit';

export function DoughnutRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={DoughnutEdit} />
			<Route path={`${match.path}/delete/:id`} component={DoughnutDelete} />
			<AuthorizeRoute path={`${match.path}/edit`} component={DoughnutEdit} />
			<Route path={`${match.path}`} component={DoughnutList} />
		</Switch>
	);
}