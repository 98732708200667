import React, { Component } from "react";
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
import doughnutService from '../doughnuts/DoughnutService';
import { Marker, Popup } from 'react-leaflet'
import Doughnut from '../charts/Doughnut';
import {
    FormGroup, Form, Label, Input, Button, Table,
    Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, ButtonGroup,
    InputGroup
} from 'reactstrap';

class DoughnutMapPage extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            selectedfeature: null,
            doughnuts: [],
            selecteddoughnut: null
        };
        this.toggleModal = this.toggleModal.bind(this);
    }
    onClickHandler = (d) => {
        this.getSelectedDoughnutData(d);
    }
    async getSelectedDoughnutData(d) {
        try {
            const data = await doughnutService.get(d.id);
            this.setState({ selecteddoughnut: data, showModal:true });
        }
        catch (error) {
            // Handle error
        }
    }
    async getDoughnutData() {
        try {
            const data = await doughnutService.getlist();
            let validlnglatlist = [];
            for (var d of data) {
                if (d.longitude != 0 && d.latitude != 0) {
                    validlnglatlist = [...validlnglatlist, d];
                }
            }
            this.setState({ doughnuts: validlnglatlist });
        }
        catch (error) {
            // Handle error
        }
    }
    componentDidMount() {
        this.getDoughnutData();
    }
    //async getWorkPrograms(pathid) {
    //    try {
    //        const data = await workProgramService.bypathid(pathid);
    //        this.setState({ workprograms: data });
    //    }
    //    catch (error) {
    //        // Handle error
    //    }
    //}
    toggleModal(e) {
        this.setState({
            showModal: !this.state.showModal
        });
    }
    handleCancel = () => {
        this.setState({ showModal: false, location: null, gridref: '', feature: null, path: null, workprograms: [] });
    }
    render() {
        return (
            <div style={{ position: "absolute", left: "0", width: "100%", height: "calc(100% - 80px)" }}>
                <PathMap onClick={this.onClickHandler} selectedLocation={this.state.location} isStatic={true}>
                    {
                        this.state.doughnuts.map((o) => (
                            <Marker
                                position={{ lat: o.latitude, lng: o.longitude }}
                                onMouseOver={e => {
                                    e.target.openPopup();
                                }}
                                onMouseOut={e => {
                                    e.target.closePopup();
                                }}
                                key={o.id}
                                onClick={() => this.onClickHandler(o) }
                            >
                                <Popup>
                                    <div>{o.name}</div>
                                    <div>{o.description}</div>
                                    <div>Click on marker to show</div>
                                </Popup>
                            </Marker>
                        ))
                    }
                </PathMap>
                <Modal isOpen={this.state.showModal} toggle={this.toggleModal} size="lg" scrollable>
                    <ModalHeader>
                        {this.state.selecteddoughnut ? this.state.selecteddoughnut.name : ''}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.selecteddoughnut ? (
                            <Row>
                                <Col xs="12">{this.state.selecteddoughnut.description}</Col>
                                <Col xs="8">{ }</Col>
                            </Row>
                        ) : ''}
                        {this.state.selecteddoughnut ? (
                            <Row>
                                <Col xs="12">
                                    <Doughnut data={JSON.parse(this.state.selecteddoughnut.chartJson)} type="c" />
                                </Col>
                            </Row>
                        ): ''}
                        {this.state.selecteddoughnut ?
                            (<Row>
                                <Col xs="4"><b>Latitude</b></Col>
                                <Col xs="8">{this.state.selecteddoughnut.latitude.toFixed(5)}</Col>
                            </Row>) : null}
                        {this.state.selecteddoughnut ?
                            (<Row>
                                <Col xs="4"><b>Longitude</b></Col>
                                <Col xs="8">{this.state.selecteddoughnut.longitude.toFixed(5)}</Col>
                            </Row>) : null}
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-primary' onClick={this.handleCancel}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default withTranslation('en')(DoughnutMapPage);