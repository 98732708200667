import React, { Component } from 'react';
import { withRouter } from 'react-router';
import doughnutService from './DoughnutService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HuePicker, SketchPicker } from 'react-color';
import DoughnutDataEditor from './DoughnutDataEditor';
import { doughnutTemplate } from './DoughnutData';
import Doughnut from '../charts/Doughnut';

import LatLngSelector from '../maps/LatLngSelector'

class DoughnutEditPlain extends Component {
	constructor(props) {
		super(props);
		this.state = { entity: null, loading: true };

		const { match } = this.props;
		this.id = match.params.id;
	}

	componentDidMount() {
		console.log(this.id);
		if (this.id && this.id != "") {
			this.retrieveFormData();
		}
		else {
			this.setState({
				loading: false,
				isnew: true,
				entity: {
					name: "",
					description: "",
					longitude: 0,
					latitude: 0,
					chartJson: doughnutTemplate
				}
			});
        }
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.state.entity[name] = value;
		this.setState({ entity: this.state.entity });
	}

	handleClickCancel = () => {
		const { history } = this.props;

		history.push('/doughnuts');
	}

	handleValidSubmit = (event, values) => {
		const { history } = this.props;
		let processedValues = this.state.entity;//values;
		processedValues.chartJson = JSON.stringify(this.state.entity.chartJson);

		if (this.state.isnew && this.state.isnew === true) {
			(async () => {
				await doughnutService.add( processedValues);
				history.push('/doughnuts');
			})();
		} else {
			(async () => {
				await doughnutService.update(this.id, processedValues);
				history.push('/doughnuts');
			})();
        }
	}
	handleDataUpdate = (d) => {
		let newentity = this.state.entity;
		newentity.chartJson = d;
		this.setState({ entity: newentity });
	}
	handleChangeLngLat = (l) => {
		console.log(l);
		let newentity = this.state.entity;
		newentity.longitude = l.lng;
		newentity.latitude = l.lat;
		this.setState({ entity: newentity });
	}

	renderForm(entity) {
		const { t, i18n } = this.props;
		return (
			<AvForm model={entity} onValidSubmit={this.handleValidSubmit}>
				<AvField name="id" type="hidden" />
				<AvField name="rowVersion" type="hidden" />
				<FormGroup>
					<Label for="name">Name</Label>
					<Input type="text" name="name" value={this.state.entity.name} onChange={this.handleInputChange} invalid={this.state.entity.name && this.state.entity.name.length > 0 ? false : true}/>
				</FormGroup>
				<FormGroup>
					<Label for="description">Description</Label>
					<Input type="text" name="description" value={this.state.entity.description} onChange={ this.handleInputChange } />
				</FormGroup>
				<Doughnut data={this.state.entity.chartJson} type="c" />
				<Doughnut data={this.state.entity.chartJson} type="npc" />
				<div>&nbsp;</div>
				<DoughnutDataEditor data={this.state.entity.chartJson} onUpdate={(d) => { this.handleDataUpdate(d) }} />
				<div>&nbsp;</div>
				<div>Location (click on map to set)</div>
				<LatLngSelector value={{ lng: this.state.entity.longitude, lat: this.state.entity.latitude }} onChange={this.handleChangeLngLat} />
				<div>&nbsp;</div>
				<FormGroup>
					<Button>Save</Button>&nbsp;
					<Button onClick={this.handleClickCancel}>Cancel</Button>
				</FormGroup>
			</AvForm>
		);
	}

	render() {
		let contents = this.state.loading
			? <p><em>Loading...</em></p>
			: this.renderForm(this.state.entity);

		return (
			<div>
				<h1 id="tabelLabel">{this.state.isnew ? "New" : ""} Doughnut</h1>
				{contents}
			</div>
		);
	}

	async retrieveFormData() {
		const data = await doughnutService.get(this.id);
		data.chartJson = JSON.parse(data.chartJson);
		this.setState({ entity: data, loading: false });
	}
}

export const DoughnutEdit = withTranslation()(withRouter(DoughnutEditPlain));