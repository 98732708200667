import React, { Component, useState, useEffect } from 'react';
import {
    Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    Dropdown, DropdownMenu, DropdownToggle, DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import authService from './api-authorization/AuthorizeService'
//import { UserRoles } from './api-authorization/ApiAuthorizationConstants';
import { withTranslation } from 'react-i18next';
import { FaWrench, FaHome, FaCalendarAlt, FaMapMarkerAlt, FaPencilAlt } from 'react-icons/fa';

/*export default function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
}*/

class NavMenuPlain extends Component {
    static displayName = NavMenuPlain.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isAuthenticated: false,
            role: null,
            user: null,
            firstname: null,
            width: window.innerWidth, height: window.innerHeight,
            dropdownOpen: false,
            //document.getElementsByClassName('navbar-toggler-icon')[0].
        };

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        window.addEventListener('resize', this.updateDimensions);
        this.populateState();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);
        this.setState({
            isAuthenticated,
            role: user && user.role,
            user: user,
            firstname: user && user.firstname
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
        //const { t, i18n } = this.props; 
        const { t } = this.props;
        const { collapsed, role } = this.state;
        return (
            <header>
                <Navbar className="navbar-expand-md navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" dark>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img src={require('../images/logo.png')} className="logobgborder"/></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!this.state.collapsed} navbar>
                            <Nav className="navbar-nav mr-auto" > {/*navbar-nav flex-grow*/}
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/"><FaHome className='nav-link-icon' /> Home</NavLink>
                                </NavItem>
                                {this.state.role && this.state.role.includes("StaffMember") ? (
                                    <div></div>
                                ) : ""}
                                {this.state.role && this.state.role.includes("StaffMember") ? (
                                    <div></div>
                                ) : ""}
                                {this.state.role && this.state.role.includes("Administrator") ? (
                                    <Dropdown className="text-light" nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                        <DropdownToggle className="text-light" nav caret>
                                            <FaWrench /> Admin
                                        </DropdownToggle>
                                        <DropdownMenu className="text-light">
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-light" to="/users/activate">{t('User Activation')}</NavLink>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavLink tag={Link} className="text-light" to="/users">{t('Users')}</NavLink>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                ) : null}
                                <LoginMenu>
                                </LoginMenu>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
export const NavMenu = withTranslation()(NavMenuPlain);
